.floating-button-PhonePanel {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .floating-button-PhonePanel:hover {
    background-color: #ad06c4;
    transform: scale(1.1);
  }
  
  .phone-panel {
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 300px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    transition: transform 0.3s, opacity 0.3s;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .registration-status {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .registered {
    color: green;
  }
  
  .not-registered {
    color: red;
  }
  
  .dial-display {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .dial-pad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .dial-button {
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .dial-button:hover {
    background-color: #e0e0e0;
    transform: scale(1.1);
  }
  
  .call-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .call-button, .end-call-button, .transfer-button, .create-task-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .call-button:hover, .end-call-button:hover, .transfer-button:hover, .create-task-button:hover {
    background-color: #0056b3;
    transform: scale(1.1);
  }
  
  .call-timer {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: #333;
  }
  
  .call-indicator {
    text-align: center;
    color: red;
    font-weight: bold;
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  